import React from 'react';

import LogoFooter from '../../assets/Common/LogoFooter.png';

const Footer = () => {
  return (
    <div className='flex md:flex-row flex-col w-full items-center lg:gap-10 gap-3 py-10 lg:px-20 md:px-5 h-auto'>
      <div>
        <img src={LogoFooter} alt='Trifolium Logo' />
      </div>
      <div className='flex flex-col md:items-start items-center text-lgreen text-base'>
        <p>Avenida 5 de Outubro 85 – 8º</p>
        <p>Avenidas Novas</p>
        <p>1050-050 Lisboa</p>
      </div>
      <div className='flex flex-col md:items-start items-center text-lgreen text-base'>
        <p>+ 351 21 357 60 85</p>
        <p className='opacity-0 block'>.</p>
        <p>office@trifoliumfarms.com</p>
      </div>
      <div className='flex flex-col md:justify-end justify-center text-lgreen text-base'>
        <p className='opacity-0 block'>.</p>
        <p className='opacity-0 block'>.</p>
        <p>Copyright © 2023 Trifolium Farms.</p>
      </div>
    </div>
  )
}

export default Footer