import React from 'react';

const ContactComponent = () => {
    return (
        <div className='flex flex-col overflow-x-hidden h-screen md:p-20 p-5 relative'>
            <div className='border-y-2 border-black/20 grid xl:grid-cols-2 lg:grid-cols-3 grid-cols-1 text-black lg:text-4xl md:text-3xl text-2xl my-20 md:py-14 py-10 gap-x-20'>
                <div className='flex lg:justify-end col-span-1 lg:pb-0 pb-10'>
                    Contact
                </div>
                <div className='flex flex-col xl:col-span-1 lg:col-span-2 col-span-1 leading-[45px]'>
                    <p>Avenida 5 de Outubro 85 – 8º<br />
                        Avenidas Novas<br />
                        1050-050 Lisboa
                    </p>
                    <p className='mt-10'>+ 351 21 357 60 85</p>
                    <p className='mt-10'>For business enquiries please contact:</p>
                    <p className='underline'>office@trifoliumfarms.com</p>
                </div>
            </div>
            <h1 className='flex justify-end md:text-3xl text-2xl text-black'>
                Copyright © 2023 Trifolium Farms.
            </h1>
        </div>
    )
}

export default ContactComponent